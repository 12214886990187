import React from "react"
import Layout from "../components/layout"
import Article from "../components/article"
import SEO from '../components/SEO/seo'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default () => {
    return (
      <Layout>
        <SEO title="Tri Chu - Mainpage" description="Welcome to Tri Chu Internet humble abode"></SEO>
        <Article>
          <Row>
            <Col md={4} sm={12}>
              <p className="profile-img-holder">
                <img
                  src={"/face_01.jpg"}
                  alt="Tri Chu"
                  align="middle"
                  className="profile-img"
                />
              </p>
            </Col>
            <Col md={8} sm={12}>
              <div style={{ lineHeight: "1.6em", whiteSpace: "pre-wrap" }}>
                <p id="about">Hello, I'm Tri.</p>
                <p>
                  I design and code beautifully simple things, and I love what I
                  do. I have been coding for over a decade, turning a childhood
                  dream into a professional pursuit and way of life.
                </p>
                <p>
                  I have a passion for simple and efficient design and would
                  love to develop scalable and human friendly applications.
                </p>
                <p>
                  Seeking to be inspired, to envision the unlikely, to work hard
                  for things that are worth it, and to be surrounded by those
                  who bring out the best in me.
                </p>
              </div>
            </Col>
          </Row>
        </Article>
      </Layout>
    )}